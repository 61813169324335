<template>
  <div class="container">
    <div class="content">
      <section class="section iiner-H inner-V">
        <h2 class="headline">ご入力内容をご確認ください。</h2>
      </section>
      <form class="form">
        <div class="form-sheet">
          <section class="form-section">
            <p class="form-headline">お客様情報</p>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">お名前</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <p class="form-content-row">
                  {{ info.nameSei }} {{ info.nameMei }}
                </p>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">フリガナ</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <p class="form-content-row">
                  {{ info.kanaSei }} {{ info.kanaMei }}
                </p>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">性別</p>
              </div>
              <div class="form-content">
                <p class="form-content-row" v-if="info.gender === 'male'">
                  男性
                </p>
                <p class="form-content-row" v-if="info.gender === 'female'">
                  女性
                </p>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">生年月日</p>
              </div>
              <div class="form-content">
                <p class="form-content-row">
                  {{ info.birthday | shortDate }}
                </p>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">郵便番号</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <p class="form-content-row">{{ info.zipcode }}</p>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">電話番号</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <p class="form-content-row">
                  {{ info.telephone }}
                </p>
              </div>
            </div>
          </section>
          <section class="form-section">
            <p class="form-headline">ログイン情報</p>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">メールアドレス</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <p class="form-content-row">{{ info.email }}</p>
              </div>
            </div>
            <div class="form-row">
              <div class="form-label">
                <p class="form-label-text">ご希望のパスワード</p>
                <span class="form-required">必須</span>
              </div>
              <div class="form-content">
                <p class="form-content-row" v-if="info.password">
                  <span
                    v-for="(item, index) in info.password.length"
                    :key="index"
                    >*</span
                  >
                </p>
              </div>
            </div>
          </section>
        </div>
        <div class="inner-H inner-V">
          <ul class="btnRow">
            <li class="btnRow-item">
              <button
                class="btn btn-bd-white"
                type="button"
                @click="historyBack"
              >
                前の画面へ戻る
              </button>
            </li>
            <li class="btnRow-item">
              <ActionButton
                class="btn btn-lg btn-main"
                :handle-submit="submit"
                button-text="会員登録する"
              />
            </li>
          </ul>
        </div>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "EKYCEntryConfirm",
  data: function () {
    return {
      //headerData
      pageName: "新規会員登録",
    };
  },
  computed: {
    info() {
      return this.$store.getters['customer/registInfo'];
    },
  },
  methods: {
    async submit() {
      this.$router.push({ name: "EKYCEntryCode" });
    },
  },
  beforeDestroy() {
    if (
      this.$route.name !== "EKYCEntry" &&
      this.$route.name !== "EKYCEntryCode"
    ) {
      this.$store.dispatch('customer/resetRegistInfo');
    }
  },
};
</script>
